// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PdfReader_pdfReader__FBZcL {
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}
.PdfReader_pdfReader__FBZcL button {
  padding: 12px;
  width: 40px;
  height: 40px;
  border: none;
  color: #333;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.PdfReader_pdfReader__FBZcL button img {
  width: 16px;
  vertical-align: middle;
}
.PdfReader_pdfReader__FBZcL button:hover {
  background-color: #eee;
}

.PdfReader_fileContainer__HUV0Z {
  display: none;
}

.PdfReader_pageInfo__Dg2w3 {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: none;
  margin-bottom: 10px;
  overflow: hidden;
}

.PdfReader_pageInfoDetails__faTyY {
  color: #333;
  display: flex;
  align-items: center;
  padding: 0 1em;
}`, "",{"version":3,"sources":["webpack://./src/lib/components/PdfReader/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,aAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;EACA,sBAAA;AACN;AAEI;EACE,sBAAA;AAAN;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,+CAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAFF","sourcesContent":[".pdfReader {\n  width: 100%;\n  display: flex;\n  font-size: 14px;\n  justify-content: center;\n  align-items: center;\n\n  button {\n    padding: 12px;\n    width: 40px;\n    height: 40px;\n    border: none;\n    color: #333;\n    border: none;\n    background-color: transparent;\n    outline: none;\n    cursor: pointer;\n\n    img {\n      width: 16px;\n      vertical-align: middle;\n    }\n\n    &:hover {\n      background-color: #eee;\n    }\n  }\n}\n\n.fileContainer {\n  display: none;\n}\n\n.pageInfo {\n  display: flex;\n  align-items: center;\n  background: white;\n  border-radius: 8px;\n  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n  border: none;\n  margin-bottom: 10px;\n  overflow: hidden;\n}\n\n.pageInfoDetails {\n  color: #333;\n  display: flex;\n  align-items: center;\n  padding: 0 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdfReader": `PdfReader_pdfReader__FBZcL`,
	"fileContainer": `PdfReader_fileContainer__HUV0Z`,
	"pageInfo": `PdfReader_pageInfo__Dg2w3`,
	"pageInfoDetails": `PdfReader_pageInfoDetails__faTyY`
};
export default ___CSS_LOADER_EXPORT___;
